import React from "react"
import { css } from "@emotion/core"

export default function Footer() {
  return (
    <footer
      css={css`
        margin-top: 60px;
        padding-bottom: 20px;
        color: #aaa;
        size: smaller;
        text-align: center;
      `}
    >
      © {new Date().getFullYear()} Noah Tye
    </footer>
  )
}
